<template>
  <div>
    <v-form ref="form" class="multi-col-validation mt-6">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">USER's DETAILS</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-col
            md="4"
            cols="12"
          ></v-col>
          <v-row>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="id_no"
                label="ID NO"
                dense
                outlined
                :rules="rules.default_max_100_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="password"
                label="PASSWORD"
                dense
                outlined
                :rules="rules.default_max_100_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="name"
                label="Name"
                dense
                outlined
                :rules="rules.default_max_100_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="positions"
                dense
                outlined
                label="Positions"
                :items="['GENERAL MANAGER','BRANCH MANAGER','ADMIN','TELLER','ACCOUNT']"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                dense
                outlined
                class="mx-2"
                v-model="branch"
                :items="branch_items"
                item-text="branch_code"
                item-value="id"
                label="Branch"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-col cols="12" v-show="alert">
        <v-alert
          color="warning"
          text
          class="mb-0"

        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{alert_message}}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="primary"
          class="me-3 mt-4"
          @click="save_staff_details"
          v-if="!saving"
        >
          Save changes
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
      </v-col>
    </v-form>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    data() {
      return {
        branch: '',
        branch_items: [],


        saving: false,
        alert: false,
        alert_message: '',

        name: '',
        id_no: '',
        password: '',
        positions: '',
      }
    },
    created() {
      const data = new FormData()
      data.append('active', 0);
      this.get_list_of_all_active_branch(data)
        .then(response => {
          this.branch_items = response.data
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('branch', ['get_list_of_all_active_branch']),
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('users', ['register_user', 'is_user_already_encoded']),
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''

        this.name = ''
        this.password = ''
        this.id_no = ''
        this.positions = ''
      },
      save_staff_details() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var index = this.branch_items.map(function (x) {
            return x.id;
          }).indexOf(this.branch)
          if (index != -1) {
            const data = new FormData()
            data.append('branch_id', this.branch_items[index].id);
            data.append('name', this.name.toUpperCase());
            data.append('password', this.password);
            data.append('id_no', this.id_no);
            data.append('positions', this.positions);
            this.is_user_already_encoded(data)
              .then(response => {
                if (response.data == 1) {
                  this.alert = true
                  this.alert_message = 'User Already Encoded!'
                  this.saving = false
                } else {
                  this.register_user(data)
                    .then(response => {
                      this.change_snackbar({
                        show: true,
                        color: 'success',
                        text: response.data,
                      })
                      this.reset()
                    })
                    .catch(error => {
                      this.alert = true
                      this.alert_message = error
                      this.saving = false
                    })
                }
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    }
  }
</script>
